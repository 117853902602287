<template>
  <div>
    <h1 class="subtitle is-3 login__title">Регистрация</h1>
    <div class="columns">
      <div class="column is-4 is-offset-4">
        <section>
          <b-field label="Имя: ">
            <b-input type="name"
                     v-model="credentials.name"
                     required
                     name="name">
            </b-input>
          </b-field>
          <b-field label="Email: ">
            <b-input type="email"
                     :class="{'is-danger': !valid_email}"
                     v-model="credentials.email"
                     required
                     name="email">
            </b-input>
          </b-field>
          <b-field label="Пароль: ">
            <b-input type="password"
                     v-model="credentials.password"
                     :class="{'is-danger': !valid_password}"
                     password-reveal>
            </b-input>
          </b-field>
          <b-field label="Подтвердите пароль: ">
            <b-input type="password"
                     v-model="credentials.password_confirmation"
                     :class="{'is-danger': !valid_password || credentials.password !== credentials.password_confirmation}"
                     password-reveal>
            </b-input>
          </b-field>
        </section>
        <div class="login__button">
          <b-button type="is-primary" icon-right="arrow-right" @click="register()">Зарегистрироваться</b-button>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
export default {
  name: 'Registration',
  data() {
    return {
      credentials: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
    };
  },
  created() {
    this.$store.dispatch('STORE_CREDENTIALS', this.$route);
  },
  computed: {
    valid_email() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return this.credentials.email ? emailPattern.test(this.credentials.email) : false;
    },
    valid_password() {
      return this.credentials.password.length >= 6;
    },
    isLoading() {
      return this.$store.getters.AUTH_IS_LOADING;
    },
  },
  methods: {
    register() {
      if (this.credentials.password !== this.credentials.password_confirmation) {
        this.$toast.warning('Пароли не совпадают');
        return;
      }
      if (this.credentials.admin_token === '') {
        this.$toast.warning('Отсутствует токен для регистрации!');
        return;
      }

      if (!this.valid_email || !this.valid_password) {
        this.$toast.error('Заполните все обязательные поля');
        return;
      }

      this.$store.dispatch('SET_REGISTRATION_CREDENTIALS', this.credentials);
      this.$store.dispatch('REGISTRATE')
        .then(() => {
          this.$router.push({path: '/applications'}).catch(() => {
          });
        })
        .catch((error) => {
          const exception = error.response.data.exception;
          switch (exception) {
            case 'UserAlreadyRegisteredException':
              this.$toast.warning('Пользователь уже существует!');
              break;
            case 'InvalidRegistrationTokenException':
              this.$toast.warning('Токен для регистрации истек!');
              break;
            default:
              this.$toast.error('Не удается зарегистрировать пользователя!');
              break;
          }
        });
    },
  },
};
</script>

<style>
.login__title {
  text-align: center;
}

.login__button {
  text-align: center;
  margin-top: 30px;
}
</style>
